@black: #333;
@grey: #898989;
@green: #8DC543;
@blue: #2E57A4;
@white: #fff;
@lavander: #D2DCE9;
@lightgrey: #F2F2F2;
@darkgrey: #ABABAB;
@footgrey: #5a5a5a;


.shadow {
    box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.12);
}

.round {
    border-radius: 3px;
}

