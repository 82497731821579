header {

    nav {
        transform-style: preserve-3d;
        perspective: 3000px;
        position: relative;
        z-index: 99999999;

        .m({
            transform-style: flat;
            perspective: none;

            a.close {
                position : fixed;
                top : 0px;
                right: 13px;
                height : 46px;
                z-index: 99999999999;
                cursor: pointer;
                background: white;
                height: 27px;

                padding: 8px 5px 5px !important;

                img {
                    height: 10px;
                    padding: 8px 0;


                }
            }



            .dropdown-holder {
                display: none;
    // display: block  !important;
}
.dropdown__wrap {
    background: white;
    position: fixed !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-y: scroll !important;
    padding-top: 18px;

    .dropdown-menu {
        opacity : 1 !important;
        pointer-events: all;

        .dropdown-menu__content {
            position: relative;
            display: block;
        }

        & > * {
            opacity: 1 !important;
        }
    }
}

});



        ul.menu {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;

            a {
                font-size: 14px;
                font-weight: 600;
                color: @black;
                text-decoration: none;
            }
            img.logo {
                height: 43px;
                width: auto;
            }


            .menuitem{
                flex: 1 1 auto;
                position: relative;
                text-align: right;
                height: 70px;
                line-height: 70px;

                &:hover > .sub-menu-shadow{
                    display: block;
                }
            }

            .sub-menu-shadow{
                position: absolute;
                display: none;
            }

            .burger {
                cursor: pointer;
            }

            .burger img {
                height: 10px;
                padding: 8px 0;
            }
        }

        .dropdown-holder{
            position: absolute;
            width: 100%;
            left: 0;
            top: 100%;

            .m({
                display: none;
                })
        }

        .dropdown__bg, .dropdown__arrow{
            position: absolute;
        }
        .dropdown__arrow{
            width: 0;
            height: 0;
            border-width: 10px;
            border-style: solid;
            border-color: transparent transparent white;
            top: -20px;
            opacity: 0;
            transition: .20s ease;
            pointer-events: none;
        }
        .dropdown__bg{
            width: 450px;
            height: 400px;
            background-color: white;
            opacity: 0;
            transition: .25s ease;
            min-width: 350px;
            overflow: hidden;
            z-index: -1;
            .shadow();

            .dropdown-bottom{
                background-color: #fafafa;
                position: absolute;
                width: 100%;
                left: 0;
                top: 300px;
                height: 700px;
                transition: .3s ease;
            }
        }

        .dropdown__wrap{
            overflow: hidden;
            position: absolute;
            transition: .25s ease;
            z-index: 1;

            .top-section {
                padding: 18px 9px;
            }

            .bottom-section{
                padding: 0px !important;
            }
        }

        .dropdown-menu{
            pointer-events: none;

            &.active {
                pointer-events: all;
            }

            .dropdown-menu__content{
                position: absolute;
                opacity: 0;
                transition: .25s ease;
                min-width: 350px;

                p.h4.md {
                    color: #333;

                    margin: 18px 0px 0;

                    border-bottom: 5px solid #eee;

                    font-weight: 400;
                    padding: 0 8px 8px;
                }

                ul{
                    list-style: none;
                    padding-left: 0;
                    margin: 0;
                }
                a{
                    color: inherit;
                    text-decoration: none;
                    display: block;
                    padding: 5px 0;

                    &:hover{ color: #333 }
                }
            }

            .top-section {
                padding: 0px;
                a {
                    flex: 6;
                    padding: 16px !important;
                    transition: all 0.3s;
                    display: block;
                    padding-bottom: 9px;
                    margin-bottom: 0px;
                    padding-top: 9px;
                    padding-bottom: 9px;
                    border-bottom: 1px solid #eee;
                    .m({
padding: 8px !important;
});

                    &:first-child h3 {
                        margin-top: 0px;
                    }

                    &:last-child {
                        border-bottom : 0;
                        margin-bottom: 0;
                    }

                    p.h3, p {
                        margin: 0;
                    }
                    p.h3 {
                        color: #333;
                        font-size: 14px;
                        margin-top: 0px;
                    }
                    p {
                        color: @grey;
                        font-size: 12px;
                        line-height: 16px !important;
                    }
                }

                &:hover a {
                    opacity: 0.4 !important;
                }

                &:hover a:hover {
                    opacity: 1 !important;
                    background: #EEFED8;
                   // margin: 0 -9px 9px;
                }


            }

            &#ownership .top-section {
                min-width: 350px;
            }

            &#homes .top-section, &#targets .top-section {
                display: flex;
                flex-wrap: nowrap;

                .m({
                    flex-wrap: wrap;
                    });

                a {
                    flex: 6;
                    padding: 0;
                    transition: all 0.3s;
                    display: block;
                    border-bottom: 0;

                    p.h3, p {
                        margin: 0;
                    }
                    p.h3 {
                        color: #333;
                        font-size: 14px;
                        margin-top: 9px;
                    }
                    p {
                        color: @grey;
                        font-size: 12px;
                    }
                }

            }
            &.active{
               .dropdown-menu__content{
                opacity: 1;
            }
        }
    }
}


h1 {

    .m({
        //text-align: center;
        width: 100%;
        });
}
}

.gallery {
    margin-top: 32px;
}

a.readmoredesc, a.loadmorepic {
    border-radius: 3px;
    padding: 4px 16px;
    border: 1px solid @blue;
    color: @blue;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    text-decoration: none !important;
    cursor: pointer;
    margin-top: 16px;
    display: inline-block;

    &:hover {
        color: white;
        background: @blue;
    }
}
.homehead {
    position: relative;

    form.form {
        margin: 0;
        position: absolute;
        bottom: 32px;
        right: 80px;

        .m({
display: none;
})
    }
}

.continuation {
    display: none;
    column-count: 2;
    column-fill: balance;
    margin: 32px 0;

    .m({
column-count: 1;
});

    p {
        margin-top: 0px;
    }
}

.househead {

    .description {
        margin-top: 32px;
    }


    & > div {
        width: 50%;

        
        .m({
width: 100%;
});
           }
}
