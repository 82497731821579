.finances {

    .filters {
        display: flex;
        width: 50%;

        justify-content: space-between;
        text-align: center;
        align-items: center;
        .m({
width: 100%;
margin-top: 18px;
});
        a {
            line-height: 26px;
            cursor: pointer;
            border-radius: 3px;
            font-size: 14px;
            padding: 0 9px;
            height: 26px;


            .m({
display: block;
padding: 0px 9px;

                });

            &:hover {
                background:  #eee;
            }
        }

        .bull {
            border-radius: 12px;
            border: 2px  solid @grey;
            width: 12px;
            height: 12px;
            display: inline-block;
            margin-right: 9px;
            display: none;
        }
        .mixitup-control-active{
            color:  #fff;
            font-weight: bold;
            background: #2E57A4;
            border-radius: 3px;

            .bull {
                background-color: #CC3690;
                border-color: #CC3690;
            }
        }
        
    }

    .financingoption.dur_5_years .loan {
        width: 40%;
        .m({
            width: 40%;
            });
    }

    .financingoption.dur_10_years .loan {
        width: 40%;
        .m({
            width: 40%;
            });
    }
    .financingoption.dur_15_years .loan {
        width: 40%;
        .m({
            width: 40%;
            });
    }
    .financingoption.dur_20_years .loan {
        width: 40%;
        .m({
            width: 40%;
            });
    }

    .financingoption.dur_cash_within_7_days_from_reservation_day .loan {
        width: 70%;
        .m({
            width: 70%;
            });
    }

    .financingoption.dur_24_months_zero_interest .loan {
        width: 70%;
        .m({
            width: 70%;
            });
    }

    .middle span.note {
        color: white;
    }

    .financingoption {
        margin-bottom: 18px;

        

        .dp, .loan, .resa {
            float: left;
            box-sizing: border-box;
        }

        .option {
            padding-right: 36px;
            padding-top: 22px;
            width: 100%;
            margin-bottom: 8px;

            .m({
                float: none;
                width: 100%;
                margin-bottom: 18px;
                clear: both;

                &:after {
                    visibility: hidden;
                    display: block;
                    font-size: 0;
                    content: " ";
                    clear: both;
                    height: 0;
                }
                });

            p {
                margin: 0;
            }
            .meta {
                font-family: Inter, Arial, sans-serif;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: @grey;
            }
            .optionname {
                font-family: Inter, Arial, sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 16px;
                color: #333;
                margin: 0;
                .m({
                    margin-bottom: 0px;
                    });
            }

            .sub {
                font-family: Inter, Arial, sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 11px;
                line-height: 16px !important;
                margin: 0;

                                
            }
        }

        .top {
            height : 22px;

            .note {
                color: #CC3690;
                font-family: Inter, Arial, sans-serif;
                font-style: normal;
                font-weight: bold;
                font-size: 11px;
                line-height: 13px;
                margin-left: 18px;

                display: inline-block;
            }
        }
        .bullet {
            background-color: #CC3690;
            width: 16px;
            height: 16px;
            border-radius: 16px;
            display: inline-block;
            margin-left: -6px;
            margin-top: 4px;
            position: absolute;
        }

        .middle {
            height: 28px;
            color: white;

            span.note {
                font-family: Inter, Arial, sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 28px;
                text-align: center;
                color: #fff;
                display: block;

            }
        }

        .bottom {
            height: 16px;
            padding: 0 9px;
            box-sizing: border-box;
            .m({
                height: 32px;
                });

            span.note {
                font-family: Inter, Arial, sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                display: block;
                
            }
        }

        .resa {
            width: 30%;
            .m({
width: 30%;
});
            .middle {
                background: #CC3690;
            }
            .bottom {
                color: #CC3690;
                border-radius : 0 0 0 3px;

            }
        }

        .dp {
            width: 30%;
            .m({
                width: 30%;
                });
            .middle {
                background: #8DC543;
            }
            .bottom {
                color: #8DC543;
                border-radius : 0 0 0 3px;

            }
        }

        .loan {
            .m({
width: 30%;
});
            .middle {
                background: #2E57A4;
                
            }
            .bottom {
                color: #2E57A4;
                border-radius : 0 3px 3px 0px;

            }
        }

        
    }
}
