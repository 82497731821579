.btn {
    background: @green;
    color: white !important;
    height: 42px;
    line-height: 42px;
    padding: 0 18px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 3px;
    display: block;
    border: none;

    &.small {
        font-size: 14px;
        padding: 0 9px;
    }
}


section {
    margin-top: 40px !important;
    &:before {
        content: "";
        border-top: 1px solid @lightgrey;
        height: 40px;
        width: 100%;
        display: block;

    }

    h2 {
        text-align: left;
        font-size: 32px;
        line-height: 40px;
        font-weight: 400;
        margin: 0;

        .m({
font-size: 24px;
line-height: 32px;
})
    }

    h3 {
        text-align: left;
        line-height: 32px;
        font-size: 24px;
        font-weight: 400;
        margin: 0;
        .m({
font-size: 18px;
line-height: 24px;
})
    }
}

hr {
    border: 1px solid #898989;
    margin: 0 18px;

}
