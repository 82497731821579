.wrap.flex.contenteditor {
    margin: 36px auto;

    .m({
display: block;
})
}



.column {
    flex: 1;
    flex-grow: 1;
    flex-basis: 0;
    margin-bottom: 18px;

    .picture {
        width: 100%;
    }

    .btn {
        text-decoration: none;
        display: inline-block;
    }

    .wrap {
        padding: 0;
    }
}
