section.housespecs {

    .specsarea {
        margin-top: 32px;
    }
    .video {
        width: 50%;
        padding-right: 16px;
        box-sizing: border-box;

        
        .m({
width: 100%;
padding: 0;
});
        .videocontainer {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;

            .m({
margin-bottom: 32px;
})
        }
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .specsarea {
        .m({
display: block !important;
})
    }
    dl {
flex-grow: 1;
flex-basis: 0;
flex-shrink: 0;
margin-top: 0;
        dt {
            font-family: Arial;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 36px;
            /* or 300% */
            letter-spacing: 0.1em;
            text-transform: uppercase;
        }

        dd {
            font-family: Arial;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 27px;
            margin: 0;
            padding-right: 18px;
            box-sizing: border-box;
        }
    }

    & > .wrap.flex > div {
        flex: 2;
        padding-right: 36px;
        box-sizing: border-box;
        p {
            font-size: 20px;
            line-height: 150%;
        }

    }

   }


.gallery .lightbox img {
    width: inherit;

}

.loadmorepic {
    font-weight: 600;
    color: @blue;
    text-decoration: underline;
}
