footer {
    background: @footgrey;
    margin-top: 50px;
    padding-bottom: 100px;

    #footlogo {
        display: block;
        background: white;
        max-width: 153px;
        width: 40%;
        margin: -50px auto 0;
        padding: 36px;
        position: relative;
        top: -35px;

        .shadow();
    }
}
