section.targets {

    h2 {
        margin: 0;
    }

    .flex:hover .target {
        opacity: 0.6;
    }

    .flex:hover .target:hover {
        opacity: 1;
    }

    .target {
        font-size: 14px;
        line-height: 18px;
        //flex: 1 4 24%;
        display: block;
        justify-content: space-between;
        //width: 268px;
        //height: 268px;
        background-size: cover;
        background-position: center center;
        background-repeat: none;
        transition: all 0.3s;
        cursor: pointer;
        width: 23%;
        padding: 18px;
        box-sizing: border-box;
        margin: 32px 0px;
        color: @black;
        text-decoration: none;

        .m({
width: 100% !important;
margin-top: 16px !important;
});

      

        p.youare {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 12px;
            letter-spacing: 1px;
        }

        h4 {
            font-size: 24px;
           margin: 0px;
            height: 54px;
        }

        p.link {
            //color: #638C2C;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px !important;

        }

           }
}
