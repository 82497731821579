section.cta {
    //background-image: url("/build/imgs/bgstreet.jpg");
    //background-size: cover;
    //background-position: center center;
    padding-bottom: 0px;
    background: #f4f2ef;
    margin-bottom: -40px;
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    .m({
height: 100vh;
});

    &:before {
        display: none;
    }

    .flex {
        align-items: center;
    }
    .left {
     //   color: white !important;
        text-align: left;
        width: 50%;

        .m({
width: 100%;
});

        > * {
            //color: white !important;
            text-align: left !important;

            .m({
})
        }
    }

    form {
        padding: 0;
        margin: 0 0 0px;
        input {
        }
        h3, p {
            display: none;
        }
    }
}
