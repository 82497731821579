.m(@content) {
    @media only screen and (max-width: 700px) {
        @content(); 
    }
}

.t(@r) {
    @media only screen and (max-width: 900px) {
        @r(); 
    }
}

.l(@content) {
    @media only screen and (min-width: 1200px) {
        @content(); 
    }
}


.wrap {
    padding: 0 80px;
    margin: 0 auto;
    box-sizing: border-box;

    .m({
padding: 0 18px;
})
}


.flex {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    .m ({
flex-wrap: wrap;
})
}

.dd {

    .m ({
        display: none !important;
    });
}

.md {
    display: none !important;

    .m ({
display: block !important;
})
}


.lum-lightbox-inner img {
    .m({
max-width: 100% !important;
})
}
