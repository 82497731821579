form.form {
display: block;
width: 35%;
min-width: 460px;
background: rgb(244, 242, 239);
position: relative;
margin-top: 32px;
padding: 8px 18px;
margin: 32px 0;

.m({
width: 100%;
margin: 0 -18px;
min-width: 100%;
margin-top: 36px;
});

button.btn:disabled {
    background: @grey;
}

.iti.iti--allow-dropdown {
    width: 50%;

    .m({
width: 100%;
});

    .field.freeform-input {
        width: 100% !important;

        margin: 0 !important;
        padding-right: 6px !important;

        padding-left: 52px !important;

        margin-left: 0;

        &.valid {
        }
        &.error {
            border: 2px solid red;
        }
    }
}

.ff-form-success {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: @green;
    z-index: 999;
    text-align: center;
    color: white;
    font-size: 24px;
    font-weight: bold;
    padding: 36px;
    border-radius: 4px;
}

.freeform-row {
    margin: 8px 0 !important;
}
.freeform-row .freeform-column {
    padding: 0  !important;
    margin: 0 !important;

    p {
        font-size: 14px;
        line-height: 24px;
        color: @grey;
        margin: 0 0 8px;
    }
}

.freeform-row .freeform-column  {
    display: flex;
    align-items: center;

    .m({
display: block;
})
}

.freeform-row .freeform-column .freeform-input {
    width: 50%  !important;

    .m({
width: 100% !important;

    width: -moz-available !important;

    width: -webkit-fill-available  !important;

     width: fill-available  !important;
})
}

h3 {
    color: @black;
    margin: 0 ;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: left;
    border-radius: 3px 3px 0 0;
    padding:  0 0 8px;
}

label {
    color: @black;
    padding: 0px;
    font-size: 14px;
    font-weight: 400 !important;
    display: block;
    width: 50%;
    .m({
width: 100% !important;

width: -moz-available !important;

width: -webkit-fill-available  !important;

width: fill-available  !important;
margin-bottom: 8px;
})

}

input.field {
    border: 1px solid @lightgrey;
    background: @white;
    padding: 5px 4px;
    margin: 0 ;
    display: block;
    width: 50%;
    width: 50%;
    //width: -moz-available;          /* WebKit-based browsers will ignore this. */
    //width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    //width: fill-available;
    .round();

}

button.btn {
    display: block;
    width: 100%;
    width: 100%;
    //width: -moz-available;          /* WebKit-based browsers will ignore this. */
    //width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    //width: fill-available;
    //width: 100%;

    margin: 18px 0px 0;
}

.flatpickr-wrapper {
    width: 100%;
}
}
