
.homes.wrap {

    & > .flex {
        flex-wrap: wrap;
        padding-top: 36px;
        margin: 0 -18px;

        .home-vignette {
            text-decoration: none;
            background: @white;
            padding: 0 0 18px;
            flex: 1 3 26%;
            margin: 0 18px 18px;
            .m({
flex: 1 2 30%;
});
            .l({
flex: 1 2 16%;
});

            .flex {
                .m({
display: block;
})
            }


            img {
                margin: 0;
                width: 100%;
                border-radius: 3px 3px 0 0;

                .m({
width: 100%;
});
            }

            h5 {
                margin : 9px 0px 9px;
                text-decoration: none;
            }

            p {
                margin : 0;
                font-size : 12px;
                color: @grey;
                font-weight: 400;
                line-height: 16px;
                text-decoration: none;

            }

            .left {
                text-decoration: none;

                h5 {
                    color: @black;
                    text-decoration: none;
                    font-size : 16px;
                    line-height: 16px;
                    margin: 8px 0;


                }
            }

            .right {
                text-decoration: none;

                p.price {
                    color: @black;
                    text-decoration: none;
                    font-size : 14px;
                    line-height: 16px !important;
                    margin: 8px 0;
                    .m({
text-align: left;
})

                }
            }
        }
    }
}
