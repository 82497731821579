@import "normalize.less";
@import "colors.less";
@import "typography.less";
@import "responsive.less";
@import "elements.less";

@import "preheader.less";
@import "header.less";
@import "form.less";
@import "homes.less";

@import "leaflet.less";
@import "map.less";

@import "targets.less";

@import "args.less";

@import "ctasection.less";

@import "footer.less";

@import "specs.less";

@import "bar.less";
@import "gridzy-2.1.1.less";

@import "luminous-basic.less";

@import "contenteditor.less";

@import "financing.less";

@import "intlTelInput.less";


.carousel a {
    text-decoration: none;
    color: #333;
    display: table;

    .wrap {
        display: table-cell;
        width: 100%;
        height: 100%;
        vertical-align: middle;

        .thetitle {
            font-size: 36px;
            background: white;
            padding: 9px;
            margin: 0;
            display: inline-block !important;

        }
        .subtitle{
            font-size: 24px;
            background: white;
            padding: 9px;
            margin: 0;
            display: inline-block !important;


        }

        .btn {
            line-height: 42px !important;
            display: inline-block !important;
        }
    }
}


body {
    background-position: top center;
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
    overflow-x: hidden;
}


p {
    line-height: 1.45em;
}
