@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 400;
    font-display: swap;
    src: url("../../fonts/Inter-Regular.woff2") format("woff2"),
    url("../../fonts/Inter-Regular.woff") format("woff");
}

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 600;
    font-display: swap;
    src: url("../../fonts/Inter-SemiBold.woff2") format("woff2"),
    url("../../fonts/Inter-SemiBold.woff") format("woff");
}

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 700;
    font-display: swap;
    src: url("../../fonts/Inter-Bold.woff2") format("woff2"),
    url("../../fonts/Inter-Bold.woff") format("woff");
}


body {
    font-family: "Inter", Arial, sans-serif;
    color: @black;
}

h1 {
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    margin: 32px 0 16px;
    color: @black;
    b {
        font-weight: 400;
    }
    .m({
margin: 16px 0;
})
}

h2 {
    margin: 16px 0;
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    color: @black;
}

h5 {
    font-size: 24px;
    line-height: 28px important;
}


ul.checks {
    list-style-image: url('../imgs/check.svg');
    list-style-position: outside;
    padding: 0;
    margin : 0 0 0 28px;

    li {
        font-size: 16px;
        line-height: 28px;
        margin: 9px 0;
    }
}

.right {
    text-align: right;
}

p {
    font-size: 16px;
    line-height: 28px !important;
    margin-top: 8px;
}
