section.map {

    .flex {
        padding: 32px 0;
        .m({
flex-direction: column-reverse; 
})
    }

    #map-holder {
    height: 456px;
    width: 50%;
    width: 50%;
    padding-left: 18px;
    box-sizing: border-box;

    .m({
width: 100%;
padding: 0;
margin-bottom: 16px;
})

}

.destinations {
    width: 50%;
    padding-right: 18px;
    box-sizing: border-box;
    .m({
width: 100%;
padding: 0;
})

}
h4 {
    margin : 18px 0px;
}

dl {
    margin : 0px;
    display : flex;
    flex: 1 2 51%;
    flex-wrap: wrap;
    font-size: 14px;
    line-height: 28px;
    justify-content: space-between;

    dd, dt {
        width: 40%;
    }

    dd {
        text-align: right;
    }


}
}
