section.arguments {

    .arg.flex {
        justify-content: space-between;
        margin: 54px 0;
        align-items: center;

        & > div {
            width: 60%;

            .m({
width: 100%;
});

            h4 {
                font-size: 18px;
                font-weight: 600;
                line-height: 28px;
                margin: 18px 0 0;
            }

            a.btn {
                display: inline-block;
                text-decoration: none;
            }
        }

        img {
            display: block;
            width: 361px;
            .m({
width: 100%;
order: -1;
})
        }
    }

    .arg.flex:nth-child(even) {
        flex-direction: row-reverse;
        text-align: right;

        ul li {
            list-style-position: inside;
        }
    }
  }
