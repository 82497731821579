div.bar {
    position: fixed;
    bottom: 0px;
    height: 40px;
    left: 0;
    right: 0;
    background: white;
    z-index: 9999;
    margin: 0 !important;
    box-shadow: 0px 1px 14px rgba(0,0,0,.1);
    border-top: 1px solid #eee;


    .flex {
        align-items: center;
        height: 40px;
    }
    p {
        margin: 0;
        line-height: 20px !important;
        font-size: 14px;
        width: 60%;

        .m({
line-height: 16px !important;
font-size: 12px;
});

    }

    a.btn {
        text-decoration: none;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
    }
}
