#preheader {
    display: none;
    height: 36px;
    background: linear-gradient(90.24deg, #FFFFFF 2.7%, rgba(255, 255, 255, 0) 96.9%), linear-gradient(180deg, rgba(0, 0, 0, 0) 43.23%, rgba(0, 0, 0, 0.005) 70.31%, rgba(0, 0, 0, 0.03) 100%), linear-gradient(89.47deg, #F2F4EE 0.77%, rgba(225, 225, 218, 0) 99.99%);
    line-height: 36px;

    color: @grey;
    font-size: 12px;
    font-weight: bold;

    a {
        color: inherit;
        text-decoration: none;
    }
}
